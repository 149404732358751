import React, { useState, useReducer } from "react"

export const defaultInterfaceContext = {
  modal: {
    open: false,
    content: undefined,
  },
  onOpenModal: () => {},
  onCloseModal: () => {},
  onSearch: () => {},
  setOnSearch: () => {},
}

export const InterfaceContext = React.createContext(defaultInterfaceContext)

const reducer = (state, action) => {
  switch (action.type) {
    case "openModal":
      return {
        ...state,
        modal: {
          open: true,
          content: action.payload,
        },
      }
    case "closeModal":
      return {
        ...state,
        modal: {
          open: false,
          content: undefined,
        },
      }
    default:
      return state
  }
}

export const InterfaceProvider = ({ children }) => {
  const [searchHandler, setSearchHandler] = useState(() => () => {})
  const [state, dispatch] = useReducer(reducer, defaultInterfaceContext)

  const setOnSearch = handler => {
    if (handler) {
      setSearchHandler(() => {
        return handler
      })
    }
  }

  const onOpenModal = content => {
    dispatch({ type: "openModal", payload: content })
  }

  const onCloseModal = () => {
    dispatch({ type: "closeModal" })
  }

  return (
    <InterfaceContext.Provider
      value={{
        ...state,
        onOpenModal,
        onCloseModal,
        onSearch: searchHandler,
        setOnSearch,
      }}
    >
      {children}
    </InterfaceContext.Provider>
  )
}
