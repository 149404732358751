export default {
  popover: `
    0 0 0 1px hsla(0, 0%, 0%, 0.1),
    0 4px 11px hsla(0, 0%, 0%, 0.1)
  `,
  tooltip: `
    0px 5px 15px 0px rgba(0, 0, 0, 0.08),
    0px 0px 0px 1px rgba(136, 152, 170, 0.1),
    0px 4px 4px 0px rgba(136, 152, 170, 0.1)
  `,
  pill: `
      rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0.12) 0px 1px 1px 0px,
      rgba(60, 66, 87, 0.16) 0px 0px 0px 1px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(60, 66, 87, 0.08) 0px 0px 5px 0px;
  `,
  pillActive: `
      rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(206, 208, 190, 0.36) 0px 0px 0px 2px,
      rgba(69,69,69, 0.8) 0px 0px 0px 2px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
      rgba(60, 66, 87, 0.16) 0px 0px 0px 1px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
      rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px
  `,
  pillActiveFocus: `
      rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(206, 208, 190, 0.36) 0px 0px 0px 2px,
      rgba(69,69,69, 0.8) 0px 0px 0px 2px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
      rgba(60, 66, 87, 0.16) 0px 0px 0px 1px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
      rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px
  `,
  invalidBoxShadow: `
      rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
      rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
      rgba(255, 66, 87, 0.8) 0px 0px 0px 1px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px;
    `,
  inputBoxShadow: `
      rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
      rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
      rgba(60, 66, 87, 0.16) 0px 0px 0px 1px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px;
    `,
  inputBoxShadowHover: `
      rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(206, 208, 190, 0.36) 0px 0px 0px 4px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
      rgba(60, 66, 87, 0.16) 0px 0px 0px 1px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
      rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px;
    `,
  buttonBoxShadow: `
      rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0.12) 0px 1px 1px 0px,
      rgba(60, 66, 87, 0.16) 0px 0px 0px 1px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(60, 66, 87, 0.08) 0px 2px 5px 0px;
    `,
  buttonBoxShadowHover: `
      rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0.12) 0px 1px 1px 0px,
      rgba(60, 66, 87, 0.16) 0px 0px 0px 1px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(60, 66, 87, 0.08) 0px 3px 9px 0px,
      rgba(60, 66, 87, 0.08) 0px 2px 5px 0px;
    `,
  buttonBoxShadowActive: `
      rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(206, 208, 190, 0.36) 0px 0px 0px 4px,
      rgba(0, 0, 0, 0.12) 0px 1px 1px 0px,
      rgba(60, 66, 87, 0.16) 0px 0px 0px 1px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(60, 66, 87, 0.08) 0px 3px 9px 0px;
      rgba(60, 66, 87, 0.08) 0px 2px 5px 0px;
    `,
  tagBoxShadow: `
      rgba(0,0,0,0) 0px 0px 0px 0px, 
      rgb(190, 191, 208) 0px 0px 0px 2px, 
      rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
      rgba(60, 62, 87, 0.22) 0px 0px 0px 2px, 
      rgba(0,0,0,0) 0px 0px 0px 0px, 
      rgba(0,0,0,0) 0px 0px 0px 0px, 
      rgba(0,0,0,0) 0px 0px 0px 0px;
    `,
  lightBoxShadow: `
    0px 2px 5px 0px #3C425714,
    0px 0px 0px 1px #3C425729,
    0px 1px 1px 0px #0000001F;
    `,
  lightBoxShadowHover: `
    0px 2px 5px 0px #3C425714, 
    0px 0px 0px 1px #3C425729,
    0px 1px 1px 0px #0000001F,
    0px 3px 9px 0px #89959C14;
  `,
  lightBoxShadowActive: `
   0px 1px 1px 0px #0000001F,
   0px 0px 0px 4px #0A314933;
  `,
  ctaBoxShadow: `
      rgba(0,0,0,0) 0px 0px 0px 0px, 
      rgba(0,0,0,0) 0px 0px 0px 0px, 
      rgba(0,0,0,0.12) 0px 1px 1px 0px, 
      rgba(59,119,255, 0) 0px 0px 0px 1px, 
      rgba(0,0,0,0) 0px 0px 0px 0px, 
      rgba(0,0,0,0) 0px 0px 0px 0px, 
      rgba(60,66,87,0.08) 0px 2px 5px 0px;
    `,
  ctaBoxShadowActive: `
      0px 1px 1px 0px #0000001F,
      0px 0px 0px 4px #0A314933
    `,
  ctaBoxShadowHover: `
      rgba(0,0,0,0) 0px 0px 0px 0px, 
      rgba(0,0,0,0) 0px 0px 0px 0px, 
      rgba(0,0,0,0.12) 0px 1px 1px 0px, 
      rgba(59,119,255, 0) 0px 0px 0px 1px, 
      rgba(0,0,0,0) 0px 0px 0px 0px, 
      rgba(59,119,255, 0.25) 0px 3px 9px 0px;
      rgba(59,119,255, 0.25) 0px 2px 5px 0px;
    `,
}
