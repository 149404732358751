import { useLDClient } from "gatsby-plugin-launchdarkly"
import React, { useEffect, useReducer } from "react"
import Medusa from "../services/api"
import { useAuth } from "./auth"

export const defaultAccountContext = {
  loading: false,
  selectedAccount: null,
  selectedEnvironment: null,
  accounts: [],
  handleSubmitEnvironment: () => {},
  handleSelectAccountOrEnv: () => {},
  handleCreateAccount: () => {}
}

export const AccountContext = React.createContext(defaultAccountContext)

const reducer = (state, action) => {
  switch (action.type) {
    case "setAccounts":
      return {
        ...state,
        accounts: action.payload || []
      }
    case "accountSelected":
      return {
        ...state,
        selectedAccount: action.payload
      }
    case "setAccounts":
      return {
        ...state,
        accounts: action.payload
      }
    case "setEnvironment":
      return {
        ...state,
        selectedEnvironment: action.payload
      }
    case "setLoading":
      return {
        ...state,
        loading: !state.loading
      }
    default:
      return state
  }
}

export const AccountProvider = ({ children }) => {
  const { user } = useAuth()
  const [state, dispatch] = useReducer(reducer, defaultAccountContext)
  const ldClient = useLDClient()

  const handleSelectAccountOrEnv = async (accountId, environmentOrId) => {
    dispatch({ type: "setLoading" })
    let acc = state.accounts.find(a => a.id === accountId)
    let env = acc?.environments[0] || null

    if (env) {
      // check if user clicked on environment or account
      if (environmentOrId) {
        // if environment, we find it and update the last used
        if (typeof environmentOrId === `string`) {
          env = acc.environments.find(e => e.id === environmentOrId)
          await Medusa.auth.setEnvironment(env.id)
        } else {
          env = environmentOrId
          await Medusa.auth.setEnvironment(environmentOrId.id)
        }
        dispatch({ type: "setEnvironment", payload: env })
      } else {
        // if account, we set the last used to the first env on the account
        await Medusa.auth.setEnvironment(env.id)
        dispatch({ type: "setEnvironment", payload: env })
      }
    } else {
      dispatch({ type: "setEnvironment", payload: null })
    }

    // finally, we retrieve the account and set it
    return Medusa.accounts.retrieve(accountId).then(({ data }) => {
      dispatch({ type: "accountSelected", payload: data.account })
      dispatch({ type: "setLoading" })
      return data
    })
  }

  const handleSubmitEnvironment = async data => {
    dispatch({ type: "setLoading" })
    try {
      const { data: sourceObject } = await Medusa.accounts.sources.create(
        state.selectedAccount.id,
        data
      )

      await handleSelectAccountOrEnv(
        state.selectedAccount.id,
        sourceObject.source.environment
      )

      const { data: accountsObject } = await Medusa.accounts.list()
      dispatch({ type: "setAccounts", payload: accountsObject.accounts })
      dispatch({ type: "setLoading" })
    } catch (error) {
      console.log(error)
      dispatch({ type: "setLoading" })
      throw Error("Could not submit environment")
    }
  }

  const setLastUsed = async allAccounts => {
    const lastUsedEnvId = user?.last_used_environment_id || undefined

    const account = allAccounts.find(ac =>
      ac.environments.find(e => e.id === lastUsedEnvId)
    )

    if (account) {
      dispatch({ type: "accountSelected", payload: account })
    } else if (allAccounts.length) {
      dispatch({ type: "accountSelected", payload: allAccounts[0] })
      return
    } else {
      return
    }

    if (!lastUsedEnvId && account.environments.length) {
      dispatch({ type: "setEnvironment", payload: account.environments[0] })
      return
    }

    const lastUsedEnv = account.environments.find(e => e.id === lastUsedEnvId)

    if (lastUsedEnv) {
      dispatch({ type: "setEnvironment", payload: lastUsedEnv })
    } else if (account.environments.length) {
      dispatch({ type: "setEnvironment", payload: account.environments[0] })
    }
  }

  const handleInitialLoad = async () => {
    dispatch({ type: "setLoading" })
    const { data } = await Medusa.accounts.list()
    dispatch({ type: "setAccounts", payload: data.accounts })
    setLastUsed(data.accounts)
    dispatch({ type: "setLoading" })
  }

  const handleCreateAccount = async payload => {
    Medusa.accounts.create(payload).then(async ({ data }) => {
      await handleInitialLoad()
      await handleSelectAccountOrEnv(data.account.id)
    })
  }

  useEffect(() => {
    if (user && !state?.accounts?.length && !state.loading) {
      handleInitialLoad()
    }
  }, [user])

  return (
    <AccountContext.Provider
      value={{
        ...state,
        handleSelectAccountOrEnv,
        handleSubmitEnvironment,
        handleCreateAccount
      }}
    >
      {children}
    </AccountContext.Provider>
  )
}
