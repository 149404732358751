import { useLDClient } from "gatsby-plugin-launchdarkly"
import React, { useEffect, useReducer } from "react"
import { Flex } from "rebass"
import Spinner from "../components/spinner"
import { trackSignup, trackLogIn, trackLogOut } from "../services/analytics"
import Medusa from "../services/api"

const AuthContext = React.createContext(null)

export const initialAuthState = {
  user: null,
  isIdle: true,
  isLoading: false,
  isLoggedIn: false
}

const reducer = (state, action) => {
  switch (action.type) {
    case "loading":
      return {
        ...state,
        isIdle: false,
        isLoading: true
      }
    case "login":
      return {
        ...state,
        isLoading: false,
        user: action.payload,
        isLoggedIn: true
      }
    case "login_pending":
      return {
        ...state,
        has_account: false,
        isLoading: false,
        isLoggedIn: false
      }
    case "reset":
      return {
        ...state,
        isLoading: false,
        isLoggedIn: false,
        user: null
      }
  }
}

export const AuthProvider = props => {
  const [auth, dispatch] = useReducer(reducer, initialAuthState)
  const ldClient = useLDClient()

  useEffect(() => {
    dispatch({ type: "loading" })
    Medusa.auth
      .session()
      .then(({ data }) => {
        dispatch({ type: "login", payload: data.user })
        return data
      })
      .catch(() => {
        dispatch({ type: "reset" })
      })
  }, [])

  const session = async () => {
    return await Medusa.auth.session().then(({ data }) => {
      return data.user
    })
  }

  const handleLogout = async () => {
    dispatch({ type: "loading" })
    return await Medusa.auth.deauthenticate().then(() => {
      dispatch({ type: "reset" })
      trackLogOut()
    })
  }

  const handleLogin = async (provider, details) => {
    dispatch({ type: "loading" })

    let authPromise
    switch (provider) {
      case "email":
        authPromise = Medusa.auth.authenticate(details)
        break
      case "github":
        authPromise = Medusa.auth.provider("github", details)
        break
      case "google":
        authPromise = Medusa.auth.provider("google", details)
        break
      default:
        throw new Error("Unknown identity provider")
    }

    const result = await authPromise
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        return {
          error: err
        }
      })

    if (result.user) {
      dispatch({ type: "login", payload: result.user })

      if (ldClient) {
        ldClient.identify({ key: result.user.id })
      }

      trackLogIn(result.user, provider)
    } else {
      dispatch({ type: "login_pending", payload: result })
    }

    return result
  }

  const handleSignup = async (provider, details, meta) => {
    let signupPromise

    switch (provider) {
      case "email": {
        signupPromise = Medusa.users.create(details)
        break
      }
      case "github":
      case "google": {
        signupPromise = Medusa.users.providerSignup(provider, details)
        break
      }
      default:
        throw new Error("Unknown identity provider")
    }

    return await signupPromise.then(({ data }) => {
      trackSignup(
        data.user,
        {
          company: meta.company,
          role: meta.role,
          industry: meta.industry
        },
        provider
      )

      dispatch({ type: "login", payload: data.user })

      return data
    })
  }

  if (auth.isIdle || auth.isLoading) {
    return (
      <Flex
        width="100%"
        height="80vh"
        justifyContent="center"
        alignItems="center"
      >
        <Spinner maxWidth="25px" dark />
      </Flex>
    )
  }

  return (
    <AuthContext.Provider
      value={{ handleSignup, handleLogin, handleLogout, session, ...auth }}
      {...props}
    />
  )
}

export const useAuth = () => {
  const context = React.useContext(AuthContext)
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider")
  }
  return context
}
