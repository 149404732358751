import axios from "axios"

const CLOUD_URL = process.env.GATSBY_CLOUD_URL || "http://localhost:6001"
const STORE_URL = process.env.GATSBY_STORE_URL || "http://localhost:3000"

const GITHUB_ENDPOINT = "https://api.github.com/user/installations"

export function githubRequest(method, token, path = "") {
  if (!token) {
    return
  }

  const client = axios.create({ baseURL: GITHUB_ENDPOINT })

  const options = {
    url: path,
    method,
    headers: {
      Accept: "application/vnd.github.v3+json",
      Authorization: `token ${token}`,
    },
  }

  return client(options)
}

const getClient = () => {
  let url = STORE_URL
  let token = ""
  let lac = ""
  if (localStorage) {
    const stored = localStorage.getItem("lurl")
    if (stored) {
      url = stored
    }

    token = localStorage.getItem("ltoken")
    lac = localStorage.getItem("lac") === "true"
  }

  if (url && token && lac) {
    return axios.create({
      baseURL: url,
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
  }

  return axios.create({
    baseUrl: STORE_URL
  })
}

export default function medusaRequest(method, path = "", payload = {}) {
  const client = getClient()

  const options = {
    method,
    withCredentials: true,
    url: path,
    data: payload,
    json: true,
  }
  return client(options)
}

export const multipartRequest = (path, payload) => {
  const client = getClient()
  const options = {
    withCredentials: true,
    headers: {
      "content-type": "multipart/form-data",
    },
  }

  return client.post(path, payload, options)
}

export function cloudRequest(method, path = "", payload = {}) {
  const client = axios.create({
    baseURL: CLOUD_URL,
  })
  const options = {
    method,
    withCredentials: true,
    url: path,
    data: payload,
    json: true,
  }
  return client(options)
}
