import React from "react"
import { ToastProvider } from "react-toast-notifications"

import { LocalDevProvider } from "./src/context/local-dev"
import { CacheProvider } from "./src/context/cache"
import { AccountProvider } from "./src/context/account"
import { InterfaceProvider } from "./src/context/interface"
import { ThemeProvider as Provider } from "./src/theme"
import { GitProvider } from "./src/context/git"
import { AuthProvider } from "./src/context/auth"

export const wrapPageElement = ({ element }) => {
  return (
    <CacheProvider>
      <GitProvider>
        <AuthProvider>
          <AccountProvider>
            <InterfaceProvider>
              <LocalDevProvider>
                <ToastProvider autoDismiss={true} placement="bottom-left">
                  <Provider>{element}</Provider>
                </ToastProvider>
              </LocalDevProvider>
            </InterfaceProvider>
          </AccountProvider>
        </AuthProvider>
      </GitProvider>
    </CacheProvider>
  )
}

export const onRouteUpdate = () => {
  window.analytics && window.analytics.page()
}
