export const trackLogIn = (user, idp) => {
  if (window.analytics) {
    window.analytics.identify(user.id, {
      email: user.email,
      firstName: user.first_name,
      lastName: user.last_name
    })

    window.analytics.track("Logged In", {
      idp_provider: idp
    })
  }
}

export const trackLogOut = () => {
  if (window.analytics) {
    window.analytics.track("Logged Out")
  }
}

export const trackSignup = (user, details, idp) => {
  if (window.analytics) {
    window.analytics.identify(user.id, {
      email: user.email,
      firstName: user.first_name,
      lastName: user.last_name,
      idp_provider: idp,
      role: details.role,
      industry: details.industry
    })

    window.analytics.track("Signed Up", {
      email: user.email,
      firstName: user.first_name,
      lastName: user.last_name,
      idp_provider: idp,
      role: details.role,
      industry: details.industry
    })
  }
}
