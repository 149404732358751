import Axios from "axios"
import React, { useEffect, useReducer } from "react"
import Medusa from "../services/api"
import { githubRequest } from "../services/request"

const LOADING_TYPES = {
  INIT: "init",
  REPOSITORY: "repository",
  INSTALLATION: "installation",
  BRANCH: "branches",
}

export const defaultGitContext = {
  provider: "",
  repositories: [],
  installations: [],
  branches: [],
  token: "",
  // loading is an object mapping provider to its loading state and type e.g. { github: true, type: repository }
  loading: {},
  gitConnect: () => {},
  fetchBranches: () => {},
  fetchRepositories: () => {},
  clearGit: () => {},
  toggleLoading: () => {},
}

export const GitContext = React.createContext(defaultGitContext)

const reducer = (state, action) => {
  switch (action.type) {
    case "setInstallations":
      return {
        ...state,
        installations: action.payload,
      }
    case "init":
      return {
        ...state,
        provider: action.payload,
      }
    case "setRepositories":
      return {
        ...state,
        repositories: action.payload,
      }
    case "setRepository":
      return {
        ...state,
        repository: action.payload,
      }
    case "setBranches":
      return {
        ...state,
        branches: action.payload,
      }
    case "setToken":
      return {
        ...state,
        token: action.payload,
      }
    case "clearGit":
      return {
        ...state,
        repositories: [],
        branches: [],
      }
    case "toggleLoading":
      return {
        ...state,
        loading: action.payload,
      }
    default:
      return state
  }
}

export const GitProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, defaultGitContext)

  const toggleLoading = (provider, b, type) => {
    dispatch({ type: "toggleLoading", payload: { [provider]: b, type } })
  }

  const gitConnect = async (provider, payload) => {
    dispatch({ type: "init", payload: provider })
    toggleLoading(provider, true, LOADING_TYPES.INIT)

    switch (provider) {
      case "github":
        // fetch the session token
        const { data: tokenObject } = await Medusa.oauth.createSession(
          "github",
          {
            code: payload.code,
            state: payload.state,
          }
        )

        dispatch({ type: "setToken", payload: tokenObject.token })

        // fetch all installations of Medusa Cloud Github App
        const { data: installationsObject } = await githubRequest(
          "GET",
          tokenObject.token
        )

        dispatch({
          type: "setInstallations",
          payload: installationsObject.installations,
        })
        break
      default:
        break
    }

    toggleLoading(provider, false, LOADING_TYPES.INIT)
  }

  const fetchRepositories = async installationId => {
    dispatch({
      type: "setRepositories",
      payload: [],
    })
    toggleLoading(state.provider, true, LOADING_TYPES.REPOSITORY)
    switch (state.provider) {
      case "github":
        const { data: repositoriesObject } = await githubRequest(
          "GET",
          state.token,
          `/${installationId}/repositories`
        )

        dispatch({
          type: "setRepositories",
          payload: repositoriesObject.repositories,
        })
        break
      default:
        break
    }
    toggleLoading(state.provider, false, LOADING_TYPES.REPOSITORY)
  }

  const fetchBranches = async repoId => {
    toggleLoading(state.provider, true, LOADING_TYPES.BRANCH)
    switch (state.provider) {
      case "github":
        const repository = state.repositories.find(
          r => r.id === parseInt(repoId)
        )

        dispatch({ type: "setRepository", payload: repository })

        const { data: branchesObject } = await Axios.get(
          `${repository.url}/branches`,
          {
            headers: {
              Accept: "application/vnd.github.v3+json",
              Authorization: `token ${state.token}`,
            },
          }
        )

        dispatch({ type: "setBranches", payload: branchesObject })
        break
      default:
        break
    }
    toggleLoading(state.provider, false, LOADING_TYPES.BRANCH)
  }

  const clearGit = () => {
    dispatch({ type: "clearGit" })
  }

  return (
    <GitContext.Provider
      value={{
        ...state,
        gitConnect,
        fetchBranches,
        fetchRepositories,
        clearGit,
        toggleLoading,
      }}
    >
      {children}
    </GitContext.Provider>
  )
}
