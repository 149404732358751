import React, { useEffect, useState } from "react"
import { useFlags } from "gatsby-plugin-launchdarkly"

export const defaultLocalDevContext = {
  localUrl: "",
  isActive: true,
}

export const LocalDevContext = React.createContext(defaultLocalDevContext)

export const LocalDevProvider = ({ children }) => {
  const [failedToFetch, setFailedToFetch] = useState(false)
  const [url, setUrl] = useState()
  const [token, setToken] = useState()
  const [isActive, setActive] = useState(true)
  const { cloudDeployment } = useFlags()

  const toggleActive = () => {
    setActive(p => {
      if (localStorage) {
        localStorage.setItem("lac", p ? "false" : "true")
      }

      return !p
    })
  }

  const setLocalUrl = url => {
    setUrl(url)
    if (localStorage) {
      localStorage.setItem("lurl", url)
    }
  }

  const setLocalToken = tok => {
    setUrl(tok)
    if (localStorage) {
      localStorage.setItem("ltoken", tok)
    }
  }

  useEffect(() => {
    if (localStorage) {
      const storedUrl = localStorage.getItem("lurl")
      if (storedUrl) {
        setUrl(storedUrl)
      }

      if (cloudDeployment) {
        const storedLac = localStorage.getItem("lac")
        if (storedLac) {
          setActive(storedLac === "true")
        }
      }

      const storedToken = localStorage.getItem("ltoken")
      if (storedToken) {
        setToken(storedToken)
      }
    }
  }, [cloudDeployment])

  // TEMP. USEEFFECT ENFORCING LOCAL DEVELOPMENT
  useEffect(() => {
    if (localStorage) {
      localStorage.setItem("lac", "true")
    }
  }, [])

  return (
    <LocalDevContext.Provider
      value={{
        localUrl: url,
        localToken: token,
        failedToFetch,
        isActive,
        toggleActive,
        setLocalUrl,
        setFailedToFetch,
        setLocalToken,
      }}
    >
      {children}
    </LocalDevContext.Provider>
  )
}
